import {
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  FormLabel,
  RequiredIndicator,
  SubmitButton,
  ValidationAlert
} from '@/components';
import { Input } from '@/components/Input/Input';
import { useCustomerLogin } from '@/hooks/useCustomerLogin';

const loginFormSchema = z.object({
  email: z.string()
    .min(1, { message: '入力してください' })
    .email({ message: 'メールアドレスの形式が正しくありません' }),
  password: z.string()
    .min(1, { message: '入力してください' })
});

type LoginFormSchema = z.infer<typeof loginFormSchema>

type EmailLoginFormProps = {
  isPayPageLogin?: boolean;
};

export const EmailLoginForm = ({ isPayPageLogin }: EmailLoginFormProps) => {
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    register,
    watch
  } = useForm<LoginFormSchema>({
    resolver: zodResolver(loginFormSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    }
  });
  const { handleLogin, validationError } = useCustomerLogin({ params: watch(), isPayPageLogin });

  return (
    <>
      <ValidationAlert
        title={'ログインに失敗しました'}
        validationError={validationError}
        mb={4}
      />
      <form onSubmit={handleSubmit(handleLogin)}>
        <FormControl
          id={'email'}
          isInvalid={!!errors.email}
          isRequired={true}
        >
          <FormLabel
            htmlFor="email"
            requiredIndicator={<RequiredIndicator/>}
          >メールアドレス</FormLabel>
          <Input
            required={false}
            placeholder="reraku@reraku.jp"
            {...register('email')}
          />
          <FormErrorMessage>
            {errors.email?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!errors.password}
          isRequired={true}
          mt={'20px'}
        >
          <FormLabel
            requiredIndicator={<RequiredIndicator/>}
          >パスワード</FormLabel>
          <Input
            type="password"
            required={false}
            placeholder="パスワード"
            {
              ...register('password')
            }
          />
          <FormErrorMessage>
            {errors.password?.message}
          </FormErrorMessage>
        </FormControl>

        <SubmitButton
          isLoading={isSubmitting}
          bg={isPayPageLogin ? 'rerakuPay.500' : 'brand.500'}
          type="submit"
          w={'100%'}
          mt={'24px'}
        >
          ログイン
        </SubmitButton>
      </form>
    </>
  );
};
