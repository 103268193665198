import { useRouter } from 'next/router';
import {
  useCallback,
  useState
} from 'react';
import { ApplicationConfig } from '@/config/applicationConfig';
import { getPageInfo } from '@/config/pages';
import {
  GraphQLValidationError,
  ValidationError
} from '@/graphql/errors';
import {
  CustomerLoginInput,
  useCustomerLoginMutation,
} from '@/graphql/generated/graphql';
import { recoilBookingInputSelectors } from '@/stores/bookingInput';
import {
  useSetCurrentCustomer
} from '@/stores/currentCustomerInfo';
import {
  noLoginCustomerInfoActions,
  noLoginCustomerInfoSelectors
} from '@/stores/noLoginCustomerInfo';

type UseCustomerLoginProps = {
  params: CustomerLoginInput;
  isPayPageLogin?: boolean;
}

export const useCustomerLogin = ({
  params,
  isPayPageLogin = false
}: UseCustomerLoginProps) => {
  const router = useRouter();
  const [validationError, setValidationError] = useState<ValidationError | null>(null);
  const bookingShop = recoilBookingInputSelectors.useShop();
  const shouldRedirectToBookingStep3 = recoilBookingInputSelectors.useShouldRedirectToStep3();
  const fromPathname = noLoginCustomerInfoSelectors.useFromPathname();
  const resetFromPathname = noLoginCustomerInfoActions.useResetFromPathname();
  const { refetch } = useSetCurrentCustomer();
  const mutation = useCustomerLoginMutation();

  const redirectForPayUrl = `${ApplicationConfig.rerakuPayUrl}/logins/sign_up`;

  /**
   * ログイン処理
   */
  const handleLogin = useCallback(async () => {
    try {
      const {
        customerLogin: {
          result,
        }
      } = await mutation.mutateAsync({
        loginByTelOrEmailInput: {
          params: {
            customerLoginParams: params
          }
        }
      });
      if (result) {
        await refetch();
        if (isPayPageLogin) {
          resetFromPathname();
          return window.location.replace(redirectForPayUrl);
        }
        // 予約画面に戻さないと行けない場合はリダイレクトする
        if (bookingShop && shouldRedirectToBookingStep3) {
          resetFromPathname();
          return await router.push(getPageInfo('studio/{slug}/booking/step3', bookingShop).href);
        }
        await router.push(fromPathname ?? getPageInfo('mypage', {}).href);
        resetFromPathname();
      }
    } catch (error) {
      if (error instanceof GraphQLValidationError) {
        return setValidationError(error.validationError);
      }
    }
  }, [mutation, params, refetch, isPayPageLogin, bookingShop, shouldRedirectToBookingStep3, router, fromPathname, resetFromPathname, redirectForPayUrl]);

  return {
    handleLogin,
    validationError,
  };
};
