import { AlertIcon } from '@chakra-ui/alert';
import {
  Alert,
  Flex,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import {
  useCallback,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';
import {
  FormLabel,
  NextButton,
  RequiredIndicator,
  SubmitButton,
  ValidationAlert
} from '@/components';
import { Input } from '@/components/Input/Input';
import { WithAuthenticationCodeInput } from '@/components/Input/WithAuthenticationCodeInput/WithAuthenticationCodeInput';
import { getPageInfo } from '@/config/pages';
import {
  usePasswordSettingForm
} from '@/features/forgotPassword/hooks/usePasswordSettingForm';
import { PasswordSettingForm } from '@/features/shread/components/ui/PasswordSettingForm/PasswordSettingForm';
import {
  GraphQLValidationError,
  ValidationError
} from '@/graphql/errors';
import {
  AuthenticationKindEnum,
  PmCustomerStatusEnum,
  useCustomerCheckStatusMutation,
  ValidateAuthenticationCodeMutationPayload,
} from '@/graphql/generated/graphql';
import { useCustomerLogin } from '@/hooks/useCustomerLogin';
import {
  passwordInput,
  telInput
} from '@/lib/zod/schema/formInputs';
import {
  defaultPasswordSettingForm,
  Steps
} from '@/stores/forgotPassword';

const telFormSchema = z.object({
  tel: telInput,
});
type TelFormSchema = z.infer<typeof telFormSchema>

const loginFormSchema = z.object({
  tel: telInput,
  password: passwordInput,
});
type LoginFormSchema = z.infer<typeof loginFormSchema>

type TelLoginFormProps = {};

export const TelLoginForm = ({}: TelLoginFormProps) => {
  const router = useRouter();
  const [validationError, setValidationError] = useState<ValidationError | null>(null);
  const [pmCustomerStatus, setPmCustomerStatus] = useState<PmCustomerStatusEnum | null>(null);
  const [passwordForgotStep, setPasswordForgotStep] = useState<Steps | null>(null);

  const telForm = useForm<TelFormSchema>({
    resolver: zodResolver(telFormSchema),
    mode: 'onChange',
    defaultValues: {
      tel: ''
    }
  });

  const {
    watch,
    formState: {
      isSubmitting,
      errors
    },
    reset,
    handleSubmit,
    register,
  } = useForm<LoginFormSchema>({
    resolver: zodResolver(loginFormSchema),
    mode: 'onChange',
    defaultValues: {
      tel: '',
      password: '',
    }
  });

  const { handleLogin, validationError: loginValidationError } = useCustomerLogin({ params: watch() });
  const checkCustomerStatusMutation = useCustomerCheckStatusMutation();
  const passwordSettingForm = usePasswordSettingForm({
    onSubmit: async () => {
      // ログイン画面へ遷移
      await router.push(getPageInfo('login', {}).href);
      setPmCustomerStatus(null);
      toast.success('パスワードの設定が完了しました。', { duration: 5000 });
    }
  });

  /**
   * telからカスタマーの状態を確認
   */
  const handleOnTelFormSubmit = useCallback(async ({ tel }: TelFormSchema) => {
    try {
      const {
        customerCheckPmCustomerStatus: {
          result,
          pmCustomerStatus
        }
      } = await checkCustomerStatusMutation.mutateAsync({
        checkPmCustomerStatusMutationInput: {
          params: {
            telOrEmail: tel,
          }
        }
      });

      if (result) {
        setPmCustomerStatus(pmCustomerStatus ?? null);
        switch (pmCustomerStatus) {
          case PmCustomerStatusEnum.Unauthenticated:
            setPasswordForgotStep(Steps.validateAuthenticationCodeForm);
            break;

          default:
            reset({
              tel,
            });
            break;
        }
      }
    } catch (error) {
      if (error instanceof GraphQLValidationError) {
        return setValidationError(error.validationError);
      }
    }
  }, [checkCustomerStatusMutation, reset]);

  /**
   * パスワード再設定に進むための認証完了時の処理
   */
  const handleOnAuthorizedCodeByPassword = useCallback(({
    pmCustomerTel,
    pmCustomerEmail,
    authenticationToken,
  }: ValidateAuthenticationCodeMutationPayload) => {
    passwordSettingForm.form.reset({
      ...defaultPasswordSettingForm,
      email: pmCustomerEmail ?? '',
      tel: pmCustomerTel ?? '',
      isRequiredEmail: !pmCustomerEmail,
      isRequiredTel: !pmCustomerTel,
      authenticationToken,
    });
    setPasswordForgotStep(Steps.passwordSettingForm);
  }, [passwordSettingForm.form]);

  const handleBack = useCallback(() => {
    setPmCustomerStatus(null);
  }, []);

  return (
    <>
      {/* 初期表示 */}
      {pmCustomerStatus === null && (
        // ログインフォーム（電話番号入力）
        <>
          <ValidationAlert
            title={'ログインに失敗しました'}
            validationError={validationError}
            mb={4}
          />
          <form onSubmit={telForm.handleSubmit(handleOnTelFormSubmit)}>
            <FormControl
              isInvalid={!!telForm.formState.errors.tel}
              isRequired={true}
              mb={'24px'}
            >
              <FormLabel
                htmlFor="email"
                requiredIndicator={<RequiredIndicator/>}
              >
                電話番号
              </FormLabel>
              <Input
                required={false}
                placeholder="09012341234"
                {...telForm.register('tel')}
              />
              <FormErrorMessage>
                {telForm.formState.errors.tel?.message}
              </FormErrorMessage>
            </FormControl>

            <SubmitButton
              isLoading={telForm.formState.isSubmitting}
              type="submit"
              w={'100%'}
            >
              次へ
            </SubmitButton>
          </form>
        </>
      )}

      {/* ログイン可能 or カスタマーが見つからないなどの場合はパスワードの入力を求める */}
      {(pmCustomerStatus === PmCustomerStatusEnum.IsAbleToLogin
        || pmCustomerStatus === PmCustomerStatusEnum.NotFound
        || pmCustomerStatus === PmCustomerStatusEnum.Unknown
      ) && (
        // ログインフォーム（パスワード入力）
        <>
          <ValidationAlert
            title={'ログインに失敗しました'}
            validationError={loginValidationError}
            mb={4}
          />
          <form onSubmit={handleSubmit(handleLogin)}>
            <FormControl
              id={'password'}
              isInvalid={!!errors.password}
              isRequired={true}
              mb={'24px'}
            >
              <FormLabel
                htmlFor="password"
                requiredIndicator={<RequiredIndicator/>}
              >パスワード</FormLabel>
              <Input
                type="password"
                required={false}
                placeholder={'パスワード'}
                {...register('password')}
              />
              <FormErrorMessage>
                {errors.password?.message}
              </FormErrorMessage>
            </FormControl>

            <Flex
              w={'full'}
              flexDirection={'row'}
              justifyContent={'center'}
              gap={'20px 20px'}
            >
              <NextButton
                isDisabled={isSubmitting}
                colorScheme={'blackAlpha'}
                w={'100%'}
                isBack={true}
                fontSize={'13px'}
                onClick={handleBack}
              >
                戻る
              </NextButton>

              <SubmitButton
                isLoading={isSubmitting}
                loadingText={'ログイン中...'}
                type="submit"
                w={'100%'}
              >
                ログイン
              </SubmitButton>
            </Flex>
          </form>
        </>
      )}

      {/* ログイン不可能なのでパスワード忘れのフォームを表示する */}
      {pmCustomerStatus === PmCustomerStatusEnum.Unauthenticated && (
        <>
          {passwordForgotStep === Steps.validateAuthenticationCodeForm && (
            // 電話番号と名前カナを入力するフォーム
            <>
              <Alert
                status={'info'}
                textStyle={'h5'}
                mb={5}
              >
                <AlertIcon/>
                本人確認のため下記フォームに追加情報を入力してください。<br/>
                入力された電話番号に本人確認のための認証コードが送信されます。<br/>
              </Alert>
              <WithAuthenticationCodeInput
                telOrEmail={'TEL_WITH_KANA'}
                authenticationKind={AuthenticationKindEnum.ResetPassword}
                defaultTel={telForm.getValues('tel')}
                onAuthorized={handleOnAuthorizedCodeByPassword}
              />
            </>
          )}

          {passwordForgotStep === Steps.passwordSettingForm && (
            // パスワードを入力するフォーム
            <>
              <Alert
                status={'info'}
                textStyle={'h5'}
                mb={5}
              >
                <AlertIcon/>
                認証コードの確認ができました。<br/>
                <br/>
                {passwordSettingForm.form.getValues('isRequiredEmail') ? (
                  <>
                    こちらのアカウントはメールアドレスとパスワードが未登録です。<br/>
                    パスワードの設定の前にメールアドレスを入力し、認証にお進みください。
                  </>
                ) : (
                  <>
                    こちらのアカウントはパスワードが未登録です。<br/>
                    パスワードを設定してください。
                  </>
                )}
              </Alert>
              <PasswordSettingForm
                form={passwordSettingForm.form}
                validationError={passwordSettingForm.validationError}
                submitBottomName={'設定する'}
                onSubmit={passwordSettingForm.handleOnSubmit}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
