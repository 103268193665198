import { zodResolver } from '@hookform/resolvers/zod';
import {
  useMemo,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import {
  GraphQLValidationError,
  ValidationError
} from '@/graphql/errors';
import {
  useCustomerResetPasswordMutation
} from '@/graphql/generated/graphql';
import {
  defaultPasswordSettingForm,
  passwordSettingFormSchema,
  PasswordSettingFormSchema
} from '@/stores/forgotPassword';

type UsePasswordSettingFormProps = {
  defaultValues?: PasswordSettingFormSchema | null,
  onSubmit?({
    formValues
  }: { formValues: PasswordSettingFormSchema }): void;
};

export const usePasswordSettingForm = ({
  defaultValues,
  onSubmit
}: UsePasswordSettingFormProps = {}) => {
  const [validationError, setValidationError] = useState<ValidationError | null>(null);
  const form = useForm<PasswordSettingFormSchema>({
    resolver: zodResolver(passwordSettingFormSchema),
    mode: 'onChange',
    defaultValues: defaultValues ?? defaultPasswordSettingForm,
  });
  const { handleSubmit, reset } = form;
  const customerResetPasswordMutation = useCustomerResetPasswordMutation();

  const handleOnSubmit = useMemo(() => {
    return handleSubmit(async (value) => {
      setValidationError(null);
      try {
        const {
          customerResetPassword: {
            result,
          }
        } = await customerResetPasswordMutation.mutateAsync({
          resetPasswordInput: {
            params: {
              resetPasswordParams: {
                tel: value.tel,
                email: value.email,
                password: value.password,
                passwordConfirmation: value.passwordConfirm,
                authenticationToken: value.authenticationToken,
              }
            }
          }
        });

        if (result) {
          onSubmit && onSubmit({ formValues: value });
          reset();
        }
      } catch (error) {
        if (error instanceof GraphQLValidationError) {
          setValidationError(error.validationError);
        }
      }
    });
  }, [customerResetPasswordMutation, handleSubmit, onSubmit, reset]);

  return {
    form,
    validationError,
    handleOnSubmit,
  };
};
