import {
  Box,
  FormControl,
  Switch,
} from '@chakra-ui/react';
import {
  useState
} from 'react';
import {
  ContentWrapper,
  FormLabel,
  TextNextLink
} from '@/components';
import { FormHeadingTitle } from '@/components/HeadingTitle/FormHeadingTitle';
import { getPageInfo } from '@/config/pages';
import { EmailLoginForm } from '@/features/customerLogin/components/ui/Forms/EmailLoginForm';
import { TelLoginForm } from '@/features/customerLogin/components/ui/Forms/TelLoginForm';
import { recoilBookingInputSelectors } from '@/stores/bookingInput';

type LoginPageProps = {};

const forgotPasswordLink = getPageInfo('forgot_password', {});
const registerLink = getPageInfo('register', {});

export const Login = ({}: LoginPageProps) => {
  // TODO: 予約時のログインでもTOPにいってしまうのでコメントアウト
  // useLoggedRedirect();

  const [isTel, setIsTel] = useState(false);
  const canMoveStep3 = recoilBookingInputSelectors.useCanMoveStep3();

  return (
    <ContentWrapper
      p={{ base: 4, lg: 8 }}
      mx={'auto'}
      maxW={{ base: 'full', lg: '60%' }}
      mb={10}
    >
      <FormHeadingTitle
        mb={6}
      >
        ログイン
      </FormHeadingTitle>

      <FormControl
        mt={'75px'}
        mb={'15px'}
        textAlign={'right'}
        display={'flex'}
        justifyContent={'flex-end'}
      >
        <Switch
          id={'isTel'}
          role={'checkbox'}
          checked={isTel}
          onChange={() => setIsTel(!isTel)}
        />
        <FormLabel
          ml={2}
          htmlFor={'isTel'}
          display={'flex'}
          alignItems={'center'}
        >
          電話番号でログイン
        </FormLabel>
      </FormControl>

      {isTel ? (
        <TelLoginForm/>
      ) : (
        <EmailLoginForm/>
      )}

      <Box
        w={'full'}
        textAlign={'center'}
        mt={5}
      >
        <TextNextLink
          textAlign={'center'}
          mt={'59px'}
          href={forgotPasswordLink.href}
        >
          {forgotPasswordLink.name}
        </TextNextLink>

      </Box>

      {canMoveStep3 && (
        <Box
          w={'full'}
          textAlign={'center'}
          mt={3}
        >
          <TextNextLink
            textAlign={'center'}
            mt={'59px'}
            href={registerLink.href}
          >
            {registerLink.name}
          </TextNextLink>
        </Box>
      )}
    </ContentWrapper>
  );
};
