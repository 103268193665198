import {
  atom,
  useRecoilCallback,
  useRecoilValue
} from 'recoil';
import { z } from 'zod';
import { RecoilAtomKeys } from '@/config/recoileKeys';
import { PmCustomerStatusEnum } from '@/graphql/generated/graphql';
import {
  authenticationTokenInput,
  emailInput,
  mobileTelInput,
  passwordInput,
} from '@/lib/zod/schema/formInputs';

export enum Steps {
  validateAuthenticationCodeForm = 'validateAuthenticationCodeForm',
  passwordSettingForm = 'passwordSettingForm',
}

/**
 * パスワード設定フォーム
 */
export const passwordSettingFormSchema = z.object({
  isRequiredEmail: z.boolean(),
  isRequiredTel: z.boolean(),
  email: emailInput.or(z.literal('')),
  tel: mobileTelInput.or(z.literal('')),
  authenticationToken: authenticationTokenInput,
  password: passwordInput,
  passwordConfirm: passwordInput,
}).superRefine(({ password, passwordConfirm }, ctx) => {
  if (password !== passwordConfirm) {
    ctx.addIssue({
      path: ['passwordConfirm'],
      code: 'custom',
      message: 'パスワードが一致しません',
    });
  }
}).superRefine(({ isRequiredTel, tel }, ctx) => {
  if (isRequiredTel && !tel) {
    ctx.addIssue({
      path: ['tel'],
      code: 'custom',
      message: '電話番号を入力してください',
    });
  }
}).superRefine(({ isRequiredEmail, email }, ctx) => {
  if (isRequiredEmail && !email) {
    ctx.addIssue({
      path: ['email'],
      code: 'custom',
      message: 'メールアドレスを入力してください',
    });
  }
});

export type PasswordSettingFormSchema = z.infer<typeof passwordSettingFormSchema>;

export const defaultPasswordSettingForm: PasswordSettingFormSchema= {
  isRequiredEmail: false,
  isRequiredTel: false,
  email: '',
  tel: '',
  authenticationToken: '',
  password: '',
  passwordConfirm: '',
};

/**
 * パスワード忘れ全体のステート
 */
export const forgotPasswordSchema = z.object({
  step: z.nativeEnum(Steps).nullable(),
  pmCustomerStatus: z.nativeEnum(PmCustomerStatusEnum).nullable(),
  passwordSettingForm: passwordSettingFormSchema.nullable(),
});

export type ForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>;

const defaultForgotPasswordState: ForgotPasswordSchema = {
  step: Steps.validateAuthenticationCodeForm,
  pmCustomerStatus: null,
  passwordSettingForm: null,
};

const forgotPasswordState = atom<ForgotPasswordSchema>({
  key: RecoilAtomKeys.FORGOT_PASSWORD,
  default: defaultForgotPasswordState
});

export const recoilForgotPasswordSelectors = {
  useState: () => {
    return useRecoilValue(forgotPasswordState);
  },
};

export const recoilForgotPasswordActions = {
  useReset: () => (
    useRecoilCallback(({ set }) => () => {
      set(forgotPasswordState, () => defaultForgotPasswordState);
    }, [])
  ),
  useMovePasswordSettingForm: () => (
    useRecoilCallback(({ set }) => (
      pmCustomerStatus: ForgotPasswordSchema['pmCustomerStatus'],
      passwordSettingForm: Partial<ForgotPasswordSchema['passwordSettingForm']>,
    ) => {
      set(forgotPasswordState, (prev) => ({
        ...prev,
        passwordSettingForm: {
          ...(prev.passwordSettingForm ?? defaultPasswordSettingForm),
          ...passwordSettingForm,
        },
        pmCustomerStatus,
        step: Steps.passwordSettingForm,
      }));
    }, [])
  ),
};
