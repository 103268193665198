import {
  BreadcrumbList,
  WithContext
} from 'schema-dts';
import { MainLayout } from '@/components';
import {
  getBreadcrumbLinks,
  getPageInfo
} from '@/config/pages';
import { Login } from '@/features/customerLogin/components/pages/Login/Login';
import { NextPage } from '@/types/nextjs';

const Page: NextPage = () => {
  return (
    <Login/>
  );
};

const jsonLd: WithContext<BreadcrumbList> = {
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  'name': 'パンくずリスト',
  'itemListElement': [{
    '@type': 'ListItem',
    'position': 1,
    'item': {
      '@id': getPageInfo('top', {}, { withFullUrl: true }).href,
      'name': getPageInfo('top', {}).name
    }
  }, {
    '@type': 'ListItem',
    'position': 2,
    'item': {
      '@id': getPageInfo('login', {}, { withFullUrl: true }).href,
      'name': getPageInfo('login', {}).name
    }
  }]
};


Page.getLayout = (page) => {
  return (
    <MainLayout
      metaTagInfo={{
        title: 'マイページログイン',
        canonicalUrl: getPageInfo('login', {}, { withFullUrl: true }).href,
      }}
      breadcrumbLinks={getBreadcrumbLinks('login', {}, {
        'top': {},
      })}
      jsonLd={JSON.stringify(jsonLd)}
      og={{
        'og:title': 'マイページログイン'
      }}
    >
      {page}
    </MainLayout>
  );
};

export default Page;
