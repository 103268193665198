import {
  Alert,
  AlertIcon,
  Flex,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import React from 'react';
import {
  UseFormReturn,
} from 'react-hook-form';
import {
  FormLabel,
  RequiredIndicator,
  SubmitButton,
  ValidationAlert
} from '@/components';
import { Input } from '@/components/Input/Input';
import { WithAuthenticationCodeInput } from '@/components/Input/WithAuthenticationCodeInput/WithAuthenticationCodeInput';
import { ValidationError } from '@/graphql/errors';
import { AuthenticationKindEnum } from '@/graphql/generated/graphql';
import { PasswordSettingFormSchema } from '@/stores/forgotPassword';

type PasswordSettingFormProps = {
  form: UseFormReturn<PasswordSettingFormSchema>;
  validationError: ValidationError | null;
  submitBottomName?: string;
  onSubmit(): void;
}

export const PasswordSettingForm = ({
  form: { register, formState: { errors, isSubmitting }, getValues, setValue, clearErrors },
  validationError,
  submitBottomName = 'パスワード変更',
  onSubmit
}: PasswordSettingFormProps) => {


  return (
    <>
      <ValidationAlert
        title={'エラーが発生しました'}
        validationError={validationError}
        mb={4}
      />
      {/* MEMO: 暫定対応 telに固定電話番号が既に登録されている、または不正な桁数の場合、パスワードの再設定ができない旨のエラーメッセージを表示 */}
      {!getValues('isRequiredTel') && getValues('tel').length !== 11 && (
        <Alert
          status={'error'}
          mb={4}
        >
          <AlertIcon/>
          登録されている電話番号が固定電話番号または認証ができない番号のため、パスワードの再設定ができません。お近くの店舗にて携帯電話番号へご変更ください。
        </Alert>
      )}
      <form onSubmit={onSubmit}>
        {getValues('isRequiredEmail') && (
          <WithAuthenticationCodeInput
            telOrEmail={'EMAIL'}
            authenticationKind={AuthenticationKindEnum.RegisterEmail}
            onAuthorized={({}, { email }) => {
              setValue('email', email ?? '');
              clearErrors('email');
            }}
            onReInput={() => setValue('email', '')}
            errorMessage={errors.email?.message}
            mb={'24px'}
          />
        )}

        {(getValues('isRequiredTel')) && (
          <WithAuthenticationCodeInput
            telOrEmail={'TEL'}
            authenticationKind={AuthenticationKindEnum.RegisterTel}
            onAuthorized={({}, { tel }) => {
              setValue('tel', tel ?? '');
              clearErrors('tel');
            }}
            onReInput={() => setValue('tel', '')}
            errorMessage={errors.tel?.message}
            mb={'24px'}
          />
        )}

        <FormControl
          isInvalid={!!errors.password}
          isRequired={true}
          mb={'24px'}
        >
          <FormLabel
            htmlFor="password"
            requiredIndicator={<RequiredIndicator/>}
          >パスワード</FormLabel>
          <Input
            id="password"
            type="password"
            required={false}
            placeholder="パスワード"
            autoComplete={'new-password'}
            {
              ...register('password')
            }
          />
          <FormErrorMessage>
            {errors.password?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!errors.passwordConfirm}
          isRequired={true}
          mb={'24px'}
        >
          <FormLabel
            htmlFor="passwordConfirm"
            requiredIndicator={<RequiredIndicator/>}
          >パスワード確認</FormLabel>
          <Input
            id="passwordConfirm"
            type="password"
            required={false}
            placeholder="パスワード確認"
            autoComplete={'new-password'}
            {...register('passwordConfirm')}
          />
          <FormErrorMessage>
            {errors.passwordConfirm?.message}
          </FormErrorMessage>
        </FormControl>

        <Flex
          w={'full'}
          justifyContent={'center'}
        >
          <SubmitButton
            w={{ base: 'full', lg: '50%' }}
            _hover={{ opacity: '0.8' }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            isLoading={isSubmitting}
            type={'submit'}
          >
            {submitBottomName}
          </SubmitButton>
        </Flex>
      </form>
    </>
  );
};
